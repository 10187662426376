import * as React from 'react'

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MenubarComponent from '../components/MenubarComponent'
import FooterComponent from '../components/FooterComponent'

const NotFound = () => (
  <>
    <div className="overflow-hidden	flex">
      <MenubarComponent />
    </div>
    <div className="mx-auto px-8 max-w-screen-2xl flex-no-wrap flex gap-12 flex-col my-16">
      <div className="grid grid-cols-2 gap-8 items-center">
        <StaticImage
          className="thumbnail"
          src="../assets/images/404.jpg"
          alt="M-Auto 404 Page not found"
          placeholder="blurred"
          // aspectRatio={1 / 1}
          height={320}
        />
        <div>
          <h1 className="text-xl">
            Strona nie istnieje...{' '}
            <Link className="mainColorDark" to="/">
              Powrót.
            </Link>
          </h1>
        </div>
      </div>
    </div>
    <FooterComponent />
  </>
)

export default NotFound
